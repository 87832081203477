<template>
  <b-card
    no-body
    class="mb-0 document-detail"
  >
    <b-tabs>
      <b-tab
        title="Thông tin hồ sơ"
        class="document-detail-tab"
        active
      >
        <chi-tiet-ho-so
          :data="data"
          :table-data="tableData"
        />
      </b-tab>
      <b-tab title="Xử lý hồ sơ">
        <xu-ly-ho-so-khong-giai-quyet />
      </b-tab>
      <b-tab
        title="Tiến trình xử lý"
      >
        <div class="text-right">
          <b-button
            size="sm"
            variant="primary"
          >
            Xem luồng xử lý
          </b-button>
        </div>
        <app-table
          class="mt-1"
          :columns="columns"
          :border-column="false"
          :table-data="[]"
          :pagination="false"
        />
        <div class="text-right mt-1">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
          >
            In chi tiết tiến độ xử lý
          </b-button>
          <b-button
            size="sm"
            variant="warning"
          >
            Đóng
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import ChiTietHoSo from '@/components/ChiTietHoSo.vue'
import {
  BTabs,
  BTab,
  BCard,
  BButton,
} from 'bootstrap-vue'
import AppTable from '@/components/AppTable.vue'
import XuLyHoSoKhongGiaiQuyet from './XuLyHoSoKhongGiaiQuyet.vue'

export default {
  name: 'ChiTietHoSoKhongGiaiQuyet',
  components: {
    ChiTietHoSo,
    XuLyHoSoKhongGiaiQuyet,
    AppTable,
    BTabs,
    BTab,
    BCard,
    BButton,
  },
  data() {
    return {
      data: {
        date: '21/03/2022 15:34:03',
        people: 'Nguyễn Văn A',
        name: 'Nguyễn Văn A',
        address: 'Ngõ 4, DUy Tân, Cầu Giấy, Hà Nội',
        email: 'TestDVC@gmail.com',
        phone: '09836091234',
        cmt: '001076511526',
      },
      tableData: [
        {
          id: 1,
          name: 'Tờ khái theo mẫu quy định',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 2,
          name: 'Văn bản ủy quyền cho cá nhân thực hiện thủ tục liên quan đến đăng ký doanh nghiệp. Văn bản ủy quyền này không bắt buộc phải công chứng. chứng nhận',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 3,
          name: 'Bản sao giấy tờ pháp lý của cá nhân được ủy quyền',
          main: 0,
          copy: 1,
          photo: 0,
          file: [],
        },
      ],
      columns: [
        {
          field: '',
          key: 't',
          title: 'STT',
          width: 50,
          align: 'center',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: '',
          key: 'a',
          title: 'Vai trò',
          align: 'center',
        },
        {
          field: 'code',
          key: 'code',
          title: 'Công việc',
          align: 'left',
        },
        {
          field: 'name',
          key: 'name',
          title: 'Thời hạn quy định',
          align: 'left',
        },
        {
          field: 'status',
          key: 'status',
          title: 'Ngày bắt đầu',
          align: 'left',
        },
        {
          field: 'status',
          key: 'time',
          title: 'Người thực hiện',
          align: 'left',
        },
        {
          field: 'status',
          key: 'actions',
          title: 'Kết quả',
          align: 'left',
        },
      ],
    }
  },
}
</script>
